// Navbar
.navbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .navbar-section {
    align-items: center;
    display: flex;
    flex: 1 0 0;

    &:not(:first-child):last-child {
      justify-content: flex-end;
    }
  }

  .navbar-center {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
  }

  .navbar-brand {
    font-size: $font-size-lg;
    text-decoration: none;
  }
}

.sticky-navigation {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background: white;
}

.mobile-nav-open .mainbar {
  position: fixed;
  background: white;
  z-index: 10;
  top: 0;
}
